import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Tesseract from 'tesseract.js';
import api from './api';
import { decodeToken, isTokenExpired } from './utils/jwt';
import { useNavigate } from 'react-router-dom';
import './App.css';
import FeedbackButton from './FeedbackButton';
import { trackEvent } from './utils/amplitude';
import { Copy, Check, Trash2 } from 'react-feather';

const examples = [
    'reflections', 'ideas', 'breakthrough', 'reports', 'email',
    'presentation pdf', 'blog post', 'newsletter', 'sales deck',
    'marketing campaign', 'pitch notes', 'business plans',
    'business proposal', 'product requirement', 'feature ideas',
    'meeting notes', 'podcast transcripts', 'lecture notes',
    'process description', 'documentation', 'code files',
    'research paper', 'case study', 'anecdotes', 'industry news',
    'trends', 'competitor analysis'
];

const fadeTransitionStyle = `
    @keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: translateY(10px);
        }
        20%, 80% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(-10px);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .example-text {
        animation: fadeInOut 3s;
        position: absolute;
        left: 0;
        right: 0;
    }

    .example-text-final {
        animation: fadeIn 1s;
        animation-fill-mode: forwards;
        position: absolute;
        left: 0;
        right: 0;
    }
`;

function FileUpload() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [extractedText, setExtractedText] = useState('');
    const [additionalContext, setAdditionalContext] = useState('');
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState('');
    const [userFiles, setUserFiles] = useState([]);
    const [userName, setUserName] = useState('');
    const [title, setTitle] = useState(''); // New state for the title
    const [uploading, setUploading] = useState(false); // Track upload status
    const [queryInProgress, setQueryInProgress] = useState(false);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [isGeneratingEbook, setIsGeneratingEbook] = useState(false);
    const [ebookProgress, setEbookProgress] = useState(0);
    const [ebookError, setEbookError] = useState(null);
    const [isGeneratingTips, setIsGeneratingTips] = useState(false);
    const [tipsProgress, setTipsProgress] = useState(0);
    const [tipsError, setTipsError] = useState(null);
    const [surfingTips, setSurfingTips] = useState(null);
    const [enhancedTips, setEnhancedTips] = useState(null);
    const [customPrompt, setCustomPrompt] = useState('');
    const [customTipsResult, setCustomTipsResult] = useState('');
    const [isProcessingCustomPrompt, setIsProcessingCustomPrompt] = useState(false);
    const [enhancementInProgress, setEnhancementInProgress] = useState(false);
    const [queryProgress, setQueryProgress] = useState(0);
    const [queryMetadata, setQueryMetadata] = useState(null);
    const [progressInterval, setProgressInterval] = useState(null); // Add this state
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [profileComplete, setProfileComplete] = useState(false);
    const [userProfile, setUserProfile] = useState({
        currentSituation: '',
        shortTermGoals: '',
        longTermGoals: ''
    });
    const [userInfo, setUserInfo] = useState({
        picture: '',
        name: ''
    });
    const [knowledgeBalance, setKnowledgeBalance] = useState(3);
    const [isPartialAnalysis, setIsPartialAnalysis] = useState(false);
    const [analysisStats, setAnalysisStats] = useState(null);
    const [hasVisited, setHasVisited] = useState(true);
    const [copied, setCopied] = useState(false);
    const [dragValue, setDragValue] = useState(knowledgeBalance);
    const [expandedFiles, setExpandedFiles] = useState({});
    const [copyStates, setCopyStates] = useState({});
    const [suggestedQuery, setSuggestedQuery] = useState('');

    const getRandomExample = useCallback((excludeExamples) => {
        const availableExamples = examples.filter(ex => !excludeExamples.includes(ex));
        const randomIndex = Math.floor(Math.random() * availableExamples.length);
        return availableExamples[randomIndex];
    }, []);

    // Start with a random example
    const firstExample = getRandomExample([]);
    const [currentExample, setCurrentExample] = useState(firstExample);
    const [showExamplesModal, setShowExamplesModal] = useState(false);
    const [isLastExample, setIsLastExample] = useState(false);

    const [websiteUrl, setWebsiteUrl] = useState('');
    
    // Replace shownExamples state with a ref
    const shownExamplesRef = useRef([firstExample]);
    
    // Handle continuous movement while dragging
    const handleSliderDrag = (e) => {
        const currentValue = parseFloat(e.target.value);
        const nearestInt = Math.round(currentValue);
        const distance = currentValue - nearestInt;
        
        let adjustedValue;
        
        if (Math.abs(distance) < 0.5) {
            // Close to an integer - apply magnetic effect
            adjustedValue = nearestInt + (distance * 0.4); // Slow down movement
        } else {
            // Far from integers - move freely
            adjustedValue = currentValue;
        }
        
        setDragValue(adjustedValue);
    };

    // Handle final value when released
    const handleSliderRelease = () => {
        const finalValue = Math.round(dragValue);
        setKnowledgeBalance(finalValue);
        setDragValue(finalValue);
        trackEvent('knowledge_balance_changed', {
            userId,
            newValue: finalValue,
            previousValue: knowledgeBalance
        });
    };

    // Combine user initialization and file fetching
    useEffect(() => {
        const initializeUserAndFiles = async () => {
            const params = new URLSearchParams(window.location.search);
            const tokenFromUrl = params.get('token');
            let token = tokenFromUrl || localStorage.getItem('token');

            console.log("params:", params)
            console.log("tokenFromUrl:",tokenFromUrl)
            console.log('Token from localStorage:', localStorage.getItem('token'));
            console.log("token:", token)

            if (tokenFromUrl) {
                // Save the token to local storage
                localStorage.setItem('token', tokenFromUrl);
                console.log('Token saved to localStorage:', tokenFromUrl)

                // Clean up the URL to remove the token
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            // Validate the token
            if (!token || isTokenExpired(token)) {
                console.log('Token is invalid or expired. Redirecting to landing page.');
                navigate('/');
                return;
            }
            
            // Decode the token to get the user ID and fetch files
            const decoded = decodeToken(token);
            console.log('decoded Token:', decoded)
            console.log('decoded username:', decoded.name)
            console.log('decoded ID:', decoded.userId)
            if (decoded) {
                setUserId(decoded.userId); // Set the user ID
                setUserName(decoded.name); // Extract the name from the token
                setUserInfo(prev => ({ ...prev, name: decoded.name }));

                try {
                    // Fetch profile
                    const profileResponse = await api.getUserProfile();
                    console.log('Profile response:', profileResponse.data);
                    
                    if (profileResponse.data) {
                        setUserProfile(profileResponse.data.profile);
                        setProfileComplete(profileResponse.data.profileComplete);
                        setHasVisited(profileResponse.data.hasVisited);
                        setUserInfo(prev => ({
                            ...prev,
                            picture: profileResponse.data.picture,
                            name: profileResponse.data.name || decoded.name
                        }));
                        
                        if (!profileResponse.data.hasVisited) {
                            setShowProfileModal(true);
                            await api.markVisited();
                        }
                    }

                    // Fetch files using the helper method
                    await fetchUserFiles(decoded.userId);

                } catch (error) {
                    console.error('Error initializing:', error);
                    if (error.response?.status === 401) {
                        navigate('/');
                    }
                }
            }
        };

        initializeUserAndFiles();
    }, [navigate]); // Include navigate in dependencies

    // Debug useEffect
    useEffect(() => {
        console.log('State Update:', {
            userId,
            userName,
            showProfileModal,
            profileComplete,
            userInfo
        });
    }, [userId, userName, showProfileModal, profileComplete, userInfo]);

    // Add this handler function
    const handleProfileSave = async () => {
        try {
            trackEvent('profile_update_started', {
                userId,
                fieldsUpdated: Object.keys(userProfile).filter(key => userProfile[key].trim() !== '')
            });

            const response = await api.updateProfile(userProfile);

            trackEvent('profile_update_successful', {
                userId,
                profileComplete: response.data.profileComplete
            });

            setProfileComplete(response.data.profileComplete);
            setShowProfileModal(false);
        } catch (error) {
            trackEvent('profile_update_error', {
                error: error.message,
                userId
            });

            console.error('Error saving profile:', error);
        }
    };

    // Add a useEffect to monitor showProfileModal state
    useEffect(() => {
        console.log('showProfileModal state:', showProfileModal);
    }, [showProfileModal]);

 
    const fetchUserFiles = async (userId) => {
        try {
            // Add error handling and logging
            console.log('Fetching files for user:', userId);
            const response = await api.get('/files'); // Make sure this matches your backend route
            console.log('Files response:', response);
            
            if (response.data) {
                setUserFiles(response.data);
                console.log('User files set:', response.data);
            } else {
                console.error('No data in response');
                setUserFiles([]);
            }
        } catch (error) {
            console.error('Error fetching user files:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
            }
            setUserFiles([]);
        }
    };

    

    // Add the style tag to inject the CSS
    useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.innerText = fadeTransitionStyle;
        document.head.appendChild(styleSheet);
        return () => styleSheet.remove();
    }, []);

    useEffect(() => {
        let currentIndex = 0;

        const interval = setInterval(() => {
            if (currentIndex < 2) {
                const nextExample = getRandomExample(shownExamplesRef.current);
                shownExamplesRef.current.push(nextExample);
                setCurrentExample(nextExample);
                currentIndex++;
                if (currentIndex === 2) {
                    setIsLastExample(true);
                }
            } else {
                clearInterval(interval);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [getRandomExample]);
 

    // Handle file selection via file input or drag-and-drop
    const handleFileChange = (file) => {
        if (file.type === 'application/pdf' || file.type.startsWith('image/')) {
            trackEvent('file_selected', {
                fileType: file.type,
                fileName: file.name,
                fileSize: file.size
            });

            setFile(file);
            setMessage(`${file.name} is ready to upload.`);
            // Decode the URI components to preserve special characters
            const decodedFileName = decodeURIComponent(file.name.replace(/\.[^/.]+$/, ""));
            setTitle(decodedFileName); // Set the title to the decoded file name without the extension
            if (file.type === 'image/*') {
                performOCR(file); // Process image files with OCR
            }
        } else {
            trackEvent('file_rejected', {
                fileType: file.type,
                fileName: file.name,
                reason: 'unsupported_format'
            });
            setMessage('Only PDFs and image files are supported.');
        }
    };    

    const performOCR = async (file) => {
        const startTime = Date.now();
        setMessage('Performing OCR...');
        trackEvent('ocr_started', {
            fileType: file.type,
            fileSize: file.size,
            userId
        });

        try {
            const { data: { text } } = await Tesseract.recognize(file, 'eng');
            trackEvent('ocr_completed', {
                textLength: text.length,
                processingTime: Date.now() - startTime,
                userId
            });

            setExtractedText(text);
            setMessage('OCR completed successfully!');
        } catch (error) {
            trackEvent('ocr_error', {
                error: error.message,
                fileType: file.type,
                userId
            });
            console.error('Error during OCR:', error);
            setMessage('Error extracting text from image');
        }
    };

    // Update your URL input field to show validation state
    const [urlError, setUrlError] = useState('');
        
    // Add this validation helper function at the top of your component
    const validateUrl = (url) => {
        if (!url || !url.trim()) {
            return ''; // Return empty string if URL is empty
        }
    
        try {
            // Remove whitespace
            let processedUrl = url.trim();
            
            // Add https:// if no protocol specified
            if (!processedUrl.match(/^[a-zA-Z]+:\/\//)) {
                processedUrl = 'https://' + processedUrl;
            }
            
            // Use URL constructor for validation
            const urlObject = new URL(processedUrl);
            
            // Check if the URL has a valid domain
            if (!urlObject.hostname.includes('.')) {
                throw new Error('Invalid domain name');
            }
            
            // Validate protocol
            if (!['http:', 'https:'].includes(urlObject.protocol)) {
                throw new Error('Only HTTP and HTTPS protocols are supported');
            }
    
            return processedUrl;
        } catch (error) {
            throw new Error('Please enter a valid website URL');
        }
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        setWebsiteUrl(url);
        
        // Clear error when input is empty
        if (!url.trim()) {
            setUrlError('');
            return;
        }
        
        // Only validate if URL looks complete
        if (url.includes('.')) {
            try {
                validateUrl(url);
                setUrlError('');
            } catch (error) {
                setUrlError(error.message);
            }
        }
    };

    const handleUpload = async () => {
        console.log("handleUpload called")
        if (!title.trim()) {
            trackEvent('upload_validation_error', {
                error: 'missing_title',
                userId
            });
            setMessage('Please add a title before uploading.');
            console.log('Upload failed: Missing title');
            return;
        }

        if (!file && !additionalContext.trim() && !websiteUrl.trim()) {
            trackEvent('upload_validation_error', {
                error: 'missing_content',
                userId
            });
            setMessage('Please upload a file or write some text to upload.');
            console.log('Upload failed: Missing file and additional context');
            return;
        }

        setUploading(true); // Set uploading state to true
        trackEvent('upload_started', {
            fileType: file?.type || websiteUrl ? 'website' : 'text_only',
            hasAdditionalContext: !!additionalContext.trim(),
            userId
        });

        let validatedUrl = '';

        try {
            // Validate URL if provided
            if (websiteUrl) {
                try {
                    validatedUrl = validateUrl(websiteUrl);
                    if (urlError) {
                        throw new Error(urlError);
                    }
                } catch (error) {
                    setMessage(error.message);
                    trackEvent('upload_validation_error', {
                        error: 'invalid_url',
                        url: websiteUrl,
                        userId
                    });
                    return;
                }
            }
    
            // Track upload attempt
            trackEvent('upload_started', {
                fileType: file?.type || (validatedUrl ? 'website' : 'text_only'),
                hasAdditionalContext: !!additionalContext.trim(),
                userId
            });
    
            const formData = new FormData();
            
            if (file) {
                formData.append('file', file);
                formData.append('extractedText', extractedText || '');
            }
            
            if (validatedUrl) {
                formData.append('websiteUrl', validatedUrl);
            }
            
            if (additionalContext) {
                formData.append('additionalContext', additionalContext.trim());
            }
            
            formData.append('title', title.trim());
    
            // Make the API call
            const response = await api.saveFile(formData);
    
            // Handle successful upload
            setUserFiles(prevFiles => [response.data.file, ...prevFiles]);
            setMessage('Upload successful!');
            
            // Track success
            trackEvent('upload_successful', {
                fileType: file?.type || (validatedUrl ? 'website' : 'text_only'),
                fileSize: file?.size,
                contextLength: additionalContext.length,
                userId
            });

            // Set the suggested query if available
            if (response.data.suggestedQuery) {
                setSuggestedQuery(response.data.suggestedQuery);
            }
    
            // Reset form
            setFile(null);
            setTitle('');
            setExtractedText('');
            setAdditionalContext('');
            setWebsiteUrl('');
            setUrlError('');
            setFileUrl(response.data.fileUrl);
    
        } catch (error) {
            trackEvent('upload_error', {
                error: error.message,
                fileType: file?.type || 'text_only',
                userId
            });

            console.error(error);
            setMessage('Error uploading file');
        } finally {
            setUploading(false); // Reset uploading state
        }
    };

    const handleQuery = async () => {
        const startTime = Date.now();
        if (!query.trim()) {
            trackEvent('query_validation_error', {
                error: 'empty_query',
                userId
            });
            return;
        }

        setQueryInProgress(true); // Start shimmer effect
        setResponse(''); // Clear previous response
        setQueryProgress(0);
        trackEvent('query_started', {
            queryLength: query.length,
            knowledgeBalance,
            userId
        });

        setIsPartialAnalysis(false);
        setAnalysisStats(null);
        
        try {
            // Start progress polling
            const interval = setInterval(async () => {
                try {
                    const progressResponse = await api.get(`/query-progress/${userId}`);
                    setQueryProgress(progressResponse.data.progress);
                } catch (error) {
                    console.error('Error fetching progress:', error);
                }
            }, 1000);

            setProgressInterval(interval); // Save the interval ID

            // Make the query request
            const res = await api.post('/query-knowledge', {
                query,
                userId: userId, // Make sure you have access to the user object
                knowledgeBalance
            });

            // Clean up progress polling
            clearInterval(interval);
            setProgressInterval(null); // Clear the interval ID
            setQueryProgress(100);
            setQueryInProgress(false); // Stop shimmer effect

            // Display response
            const fullResponse = res.data.answer;
            setQueryInProgress(false); // Stop shimmer effect once response is ready
            typeResponse(fullResponse);

            // Set partial analysis information
            setIsPartialAnalysis(res.data.metadata?.isPartialAnalysis || false);
            if (res.data.metadata) {
                setAnalysisStats({
                    processed: res.data.metadata.totalChunksProcessed || 0,
                    total: res.data.metadata.totalChunks || 0
                });
            }

            // Show confidence and sources
            setQueryMetadata(res.data.metadata);

            trackEvent('query_completed', {
                queryLength: query.length,
                responseLength: res.data.answer.length,
                processingTime: Date.now() - startTime,
                isPartialAnalysis: res.data.metadata?.isPartialAnalysis,
                confidence: res.data.metadata?.averageConfidence,
                userId
            });

        } catch (error) {
            trackEvent('query_error', {
                error: error.message,
                queryLength: query.length,
                userId
            });

            // Clean up interval if error occurs
            if (progressInterval) {
                clearInterval(progressInterval);
                setProgressInterval(null);
            }

            setQueryInProgress(false); // Stop shimmer effect
            setQueryProgress(0);
            
            if (error.response?.status === 404) {
                setResponse('You have not uploaded any files yet. Please upload a file to query your knowledge base.');
            } else {
                console.error('Error querying knowledge:', error);
                setResponse('Failed to retrieve an answer. Please try again.');
            }
        } finally {
            setQueryInProgress(false); // Stop shimmer effect
        }
    };

    // Clean up interval on component unmount
    useEffect(() => {
        return () => {
            if (progressInterval) {
                clearInterval(progressInterval);
            }
        };
    }, [progressInterval]);

    // Helper function to display response progressively
    const typeResponse = (text) => {
        let index = -1;
        const typingInterval = 5; // Milliseconds per character

        // Clear the response before typing
        setResponse("");

        const revealNextCharacter = () => {
            if (index < text.length) {
                setResponse((prev) => prev + text.charAt(index));
                index++;
                setTimeout(revealNextCharacter, typingInterval);
            }
        };

        revealNextCharacter();
    };

    // React-Dropzone for drag-and-drop functionality
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                handleFileChange(acceptedFiles[0]); // Handle only the first file for simplicity
            }
        },
        accept:{
            'application/pdf': [], // Allow PDF files
            'image/*': [] // Allow all image formats
        },
        multiple: false, // Allow only one file to be uploaded at a time
    });

    const getTimeAgo = (timestamp) => {
        if (!timestamp) return '';
        
        const now = new Date();
        const uploadDate = new Date(timestamp);
        const diffInDays = Math.floor((now - uploadDate) / (1000 * 60 * 60 * 24));
        
        if (diffInDays === 0) return 'Today';
        if (diffInDays === 1) return 'Yesterday';
        return `${diffInDays} days ago`;
    };

    const generateEbook = async () => {
        setIsGeneratingEbook(true);
        setEbookError(null);
        setEbookProgress(0);

        try {
            // Start SSE connection for progress updates
            const eventSource = new EventSource(`${api.defaults.baseURL}/ebook-progress?userId=${userId}`);
            
            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setEbookProgress(data.progress);
            };

            // Make the ebook generation request
            const response = await api.post('/generate-ebook', {
                topic: 'surfing',
                targetPages: 30
            });

            // Close SSE connection
            eventSource.close();

            // Download the generated ebook
            downloadEbook(response.data.ebook);
            
        } catch (error) {
            console.error('Error generating ebook:', error);
            setEbookError(error.response?.data?.error || 'Failed to generate ebook');
        } finally {
            setIsGeneratingEbook(false);
            setEbookProgress(0);
        }
    };

    const downloadEbook = (ebookData) => {
        // Create a Blob from the ebook content
        const blob = new Blob([JSON.stringify(ebookData, null, 2)], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${ebookData.title || 'generated-ebook'}.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const getProgressMessage = (progress) => {
        if (progress < 25) {
            return 'Analyzing your knowledge base...';
        } else if (progress < 50) {
            return 'Extracting relevant content...';
        } else if (progress < 75) {
            return 'Generating ebook outline...';
        } else if (progress < 100) {
            return 'Writing final content...';
        }
        return 'Completing generation...';
    };

    const getTipsProgressMessage = (tipsProgress) => {
        if (tipsProgress < 25) return 'Analyzing your knowledge base...';
        if (tipsProgress < 50) return 'Extracting relevant tips...';
        if (tipsProgress < 75) return 'Organizing tips by category...';
        if (tipsProgress < 100) return 'Finalizing tips collection...';
        return 'Completing generation...';
    };

    const generateTips = async () => {
        setIsGeneratingTips(true);
        setTipsError(null);
        setSurfingTips(null);
        setEnhancedTips(null);
        setCustomTipsResult('');
        setTipsProgress(0);

        try {
            // Start SSE connection for progress updates
            const eventSource = new EventSource(`${api.defaults.baseURL}/tips-progress?userId=${userId}`);
            
            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setTipsProgress(data.tipsProgress);
            };

            const response = await api.post('/generate-tips');
            setSurfingTips(response.data.tips);
            setTipsProgress(100);
            downloadTips(response.data.tips); 
        } catch (error) {
            console.error('Error generating tips:', error);
            setTipsError(error.response?.data?.error || 'Failed to generate tips');
        } finally {
            setIsGeneratingTips(false);
        }
    };

    const enhanceTips = async () => {
        setEnhancementInProgress(true);
        try {
            const response = await api.post('/enhance-tips', { tips: surfingTips });
            setEnhancedTips(response.data.enhancedTips);
        } catch (error) {
            console.error('Error enhancing tips:', error);
            setTipsError(error.response?.data?.error || 'Failed to enhance tips');
        } finally {
            setEnhancementInProgress(false);
        }
    };

    const processCustomPrompt = async () => {
        if (!customPrompt.trim()) return;
        
        setIsProcessingCustomPrompt(true);
        try {
            const response = await api.post('/process-tips-prompt', {
                tips: surfingTips,
                userPrompt: customPrompt
            });
            setCustomTipsResult(response.data.result);
        } catch (error) {
            console.error('Error processing custom prompt:', error);
            setTipsError(error.response?.data?.error || 'Failed to process prompt');
        } finally {
            setIsProcessingCustomPrompt(false);
        }
    };

    const downloadTips = (surfingTips) => {
        // Create stats from surfingTips
        const stats = {
            totalTips: Object.values(surfingTips).reduce((sum, category) => 
                sum + category.tips.length, 0),
            filesProcessed: [...new Set(Object.values(surfingTips)
                .flatMap(category => category.tips)
                .map(tip => tip.sourceFile))].length,
            categoriesCovered: Object.keys(surfingTips).length
        };

        // Create formatted content
        const content = [
            `SURFING TIPS REPORT`,
            `Generated on: ${new Date().toLocaleString()}`,
            `Total Tips: ${stats.totalTips}`,
            `Files Processed: ${stats.filesProcessed}`,
            `Categories Covered: ${stats.categoriesCovered}`,
            '\n',
        ];

        // Add tips for each category
        Object.entries(surfingTips).forEach(([category, data]) => {
            content.push(`\n== ${category} ==`);
            content.push(`Confidence Level: ${data.confidence}`);
            content.push(`Number of Techniques: ${data.techniqueCount}`);
            content.push('\nTips:');
            
            data.tips.forEach(tip => {
                content.push(`\n• ${tip.technique}:`);
                content.push(`  ${tip.tip}`);
                if (tip.sourceFile) {
                    content.push(`  Source: ${tip.sourceFile}`);
                }
            });
            content.push('\n');
        });

        // Convert to text and download
        const textContent = content.join('\n');
        const blob = new Blob([textContent], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'surfing-tips.txt';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    // Add the progress bar component
    const ProgressBar = ({ progress }) => (
        <div style={{
            width: '100%',
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            margin: '10px 0'
        }}>
            <div style={{
                width: `${progress}%`,
                backgroundColor: '#4CAF50',
                height: '10px',
                borderRadius: '10px',
                transition: 'width 0.3s ease-in-out'
            }}>
            </div>
            <div style={{
                textAlign: 'center',
                fontSize: '12px',
                marginTop: '5px'
            }}>
                {progress.toFixed(1)}%
            </div>
        </div>
    );

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(response);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setMessage('Failed to copy to clipboard');
        }
    };

    // Calculate total number of files and characters
    const totalFiles = userFiles.length;
    const totalCharacters = userFiles.reduce((acc, file) => {
        const extractedLength = file.extractedText?.length || 0;
        const contextLength = file.additionalContext?.length || 0;
        return acc + extractedLength + contextLength;
    }, 0);

    const ExamplesModal = () => (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: showExamplesModal ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '30px',
                borderRadius: '10px',
                maxWidth: '600px',
                maxHeight: '80vh',
                overflowY: 'auto',
                position: 'relative'
            }}>
                <button
                    onClick={() => setShowExamplesModal(false)}
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        background: 'none',
                        border: 'none',
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: '#666',
                        padding: '5px 10px'
                    }}
                >
                    ×
                </button>
                
                <h3>Types of content you can upload:</h3>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                    gap: '10px',
                    marginTop: '20px'
                }}>
                    {examples.map((example, index) => (
                        <div key={index} style={{
                            padding: '10px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                            textAlign: 'center'
                        }}>
                            {example}
                        </div>
                    ))}
                </div>
                <button
                    onClick={() => setShowExamplesModal(false)}
                    className="main-button"
                    style={{ marginTop: '20px',  alignSelf:'center', justifySelf:'center'}}
                >
                    Close
                </button>
            </div>
        </div>
    );

    return (
        <div
            //class="card--44" //colorful style
            // style={{height:"100vh", opacity:'0.9'}} //colorful style
            style={{
                backgroundColor:'#FBFBFB', 
                padding:'25px',
                minHeight: '100vh',  // Add this line
                boxSizing: 'border-box'  // Add this line
            }} //classic style
        >

             {/* Profile Button */}
             <div style={{ position: 'absolute', top: '50px', right: '25px', boxSizing: 'border-box' }}>
                <button
                    onClick={() => {
                        setShowProfileModal(true);
                        trackEvent('profile_modal_opened', {
                            userId,
                            action: 'open_profile_modal'
                        });
                    }}
                    style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        border: 'none',
                        padding: 0,
                        cursor: 'pointer',
                        position: 'relative',
                        backgroundColor: userInfo.picture ? 'transparent' : '#007bff',
                    }}
                >
                    {userInfo.picture ? (
                        <img 
                            src={userInfo.picture}
                            alt={userInfo.name}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '50%'
                            }}
                        />
                    ) : (
                        <i className="fas fa-user" style={{ color: 'white' }}></i>
                    )}
                    
                    {/* Notification Dot */}
                    {!profileComplete && (
                        <span style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-5px',
                            width: '12px',
                            height: '12px',
                            backgroundColor: '#ff4444',
                            borderRadius: '50%',
                            border: '2px solid white',
                        }} />
                    )}
                </button>
            </div>

            {/* Profile Modal */}
            {showProfileModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '30px',
                        borderRadius: '10px',
                        width: '90%',
                        maxWidth: '600px',
                        maxHeight: '90vh',
                        overflowY: 'auto'
                    }}>
                        <h2>Hi {(userInfo.name || userName)?.split(' ')[0]}!</h2>
                        <h2>Tell us more about you</h2>
                        <p style={{ color: '#666', marginBottom: '20px' }}>
                            This information helps us provide better personalized assistance
                        </p>
                        {!hasVisited && (
                            <p style={{ color: '#007bff', marginBottom: '20px' }}>
                                You can change it whenever.
                            </p>
                        )}

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>
                                Current Situation
                            </label>
                            <textarea
                                value={userProfile.currentSituation}
                                onChange={(e) => setUserProfile({
                                    ...userProfile,
                                    currentSituation: e.target.value
                                })}
                                placeholder="Your current job, skills, experience..."
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #dfe1e5',
                                    minHeight: '100px'
                                }}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>
                                Short Term Goals
                            </label>
                            <textarea
                                value={userProfile.shortTermGoals}
                                onChange={(e) => setUserProfile({
                                    ...userProfile,
                                    shortTermGoals: e.target.value
                                })}
                                placeholder="Goals for the next 3-6 months..."
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #dfe1e5',
                                    minHeight: '100px'
                                }}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>
                                Long Term Goals
                            </label>
                            <textarea
                                value={userProfile.longTermGoals}
                                onChange={(e) => setUserProfile({
                                    ...userProfile,
                                    longTermGoals: e.target.value
                                })}
                                placeholder="Where do you see yourself in 1-5 years..."
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #dfe1e5',
                                    minHeight: '100px'
                                }}
                            />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <button
                                onClick={() => setShowProfileModal(false)}
                                style={{
                                    padding: '8px 16px',
                                    borderRadius: '5px',
                                    border: '1px solid #dfe1e5',
                                    backgroundColor: 'white',
                                    cursor: 'pointer'
                                }}
                            >
                                Close
                            </button>
                            <button
                                onClick={handleProfileSave}
                                className="main-button"
                            >
                                Save Profile
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <h1 >Merra.ai</h1>
            {/* Display user's name */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
            {userName && <h2 style={{justifySelf:"center"}}>Hello {userName.split(' ')[0]}!</h2>}
            </div>
            {/* <h2 style={{justifySelf:"center"}}>Let's <span style={{backgroundColor: '#EFA03A', borderRadius: '8px', paddingLeft: '5px', paddingRight: '5px'}}>work on your dreams</span> together</h2> */}
            <h2 className="gradient-text" style={{justifySelf:"center", fontSize:'20px'}}>Feeling limitless today ?</h2>
            
            <div style={{display:'flex', flexDirection:'row'}}>

            <div
                style={{
                    position:"relative",
                    backgroundColor:"white",
                    borderRadius:'10px',
                    border:'1px solid #dfe1e5',
                    width:"20vw",
                    overflowY:'auto',
                    maxHeight:'70vh'
                    }}>
                {/* Top Gradient */}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        height: "50px",
                        background: "linear-gradient(to bottom, white, transparent)",
                        pointerEvents: "none", // Ensure the gradient doesn't block clicks
                        zIndex: 1
                    }}
                />

                <div
                    style={{
                        position:"relative",
                        backgroundColor:"white",
                        paddingLeft:'20px',
                        paddingRight:'20px',
                        paddingTop:'20px',
                        overflowY:'auto',
                        maxHeight:'65vh',
                    }}
                >
                
                    <h3>Previously Uploaded Files</h3>
                    <p style={{ 
                        background: 'linear-gradient(to right, #000 20%, #AB5898 50%, #5C4BD3 80%)',
                        WebkitBackgroundClip: 'text',
                        backgroundClip: 'text',
                        color: 'transparent',
                        display: 'inline-block',
                        margin: '0px'
                    }}>
                        Total files: {totalFiles} • Total characters: {totalCharacters.toLocaleString()}
                    </p>
                    {userFiles.length === 0 ? (
                        <div style={{ textAlign: 'center', marginTop: '30px' }}>
                            <p>No files uploaded yet.</p>
                            <p>
                                Start by uploading a file or text to create your knowledge base!
                            </p>
                        </div>
                        ) : (
                            <ul style={{paddingBottom:'50px', listStyle: 'none', padding: 0, marginBottom:'50px'}}>
                                {userFiles.map((file) => {
                                    const isExpanded = expandedFiles[file._id];
                                    const maxLines = 5;
                                    const lineHeight = 20;
                                    const isCopied = copyStates[file._id];

                                    const handleCopy = async () => {
                                        try {
                                            const textToCopy = `${file.extractedText ? `Extracted Text: ${file.extractedText}\n` : ''}${file.additionalContext ? `Details: ${file.additionalContext}` : ''}`;
                                            trackEvent('content_copied', {
                                                userId,
                                                contentType: 'tips',
                                                contentLength: textToCopy.length
                                            });
                                            await navigator.clipboard.writeText(textToCopy);
                                            setCopyStates(prev => ({ ...prev, [file._id]: true }));
                                            setTimeout(() => {
                                                setCopyStates(prev => ({ ...prev, [file._id]: false }));
                                            }, 2000);
                                        } catch (err) {
                                            console.error('Failed to copy text: ', err);
                                            setMessage('Failed to copy to clipboard');
                                        }
                                    };
                                
                                    const handleDelete = async (fileId) => {
                                        trackEvent('file_deletion_initiated', {
                                            userId,
                                            fileId
                                        });
                                        if (window.confirm('Are you sure you want to delete this file?')) {
                                            try {
                                                console.log('Attempting to delete file:', fileId); // Debug log
                                                const response = await api.delete(`/files/${fileId}`);
                                                
                                                if (response.status === 200) {
                                                    setUserFiles(prevFiles => prevFiles.filter(f => f._id !== fileId));
                                                    //setMessage('File deleted successfully');
                                                }
                                            } catch (error) {
                                                console.error('Error deleting file:', error);
                                                if (error.response?.status === 404) {
                                                    // setMessage('File not found or already deleted');
                                                    // Refresh the file list
                                                    // fetchUserFiles();
                                                } else {
                                                    // setMessage('Error deleting file. Please try again.');
                                                }
                                            }
                                        }
                                    };

                                    return (
                                        <li key={file._id} style={{
                                            marginBottom: '15px',
                                            padding: '10px',
                                            border: '1px solid #dfe1e5',
                                            borderRadius: '5px',
                                            overflow: 'hidden'
                                        }}>
                                            <strong style={{fontSize: '16px'}}>{file.title ? file.title : 'Untitled'}</strong>
                                            <div style={{fontSize: '12px', color: '#666', marginTop: '5px'}}>
                                                {file.createdAt && (
                                                    <span>Upload date: {getTimeAgo(file.createdAt)}</span>
                                                )}
                                            </div>
                                            
                                            <div style={{ position: 'relative' }}>
                                                <div style={{
                                                    maxHeight: isExpanded ? 'none' : `${lineHeight * maxLines}px`,
                                                    overflow: 'hidden',
                                                    position: 'relative'
                                                }}>
                                                    {file.extractedText && (
                                                        <p style={{fontSize: '14px', marginTop: '5px'}}>
                                                            <em style={{fontWeight:'bold'}}>Extracted Text:</em> {file.extractedText}
                                                        </p>
                                                    )}
                                                    {file.additionalContext && (
                                                        <p style={{fontSize: '14px', marginTop: '5px'}}>
                                                            <em style={{fontWeight:'bold'}}>Details:</em> {file.additionalContext}
                                                        </p>
                                                    )}
                                                    
                                                    {!isExpanded && (file.extractedText?.length > 150 || file.additionalContext?.length > 150) && (
                                                        <div style={{
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '25px',
                                                            background: 'linear-gradient(transparent, white)',
                                                            pointerEvents: 'none'
                                                        }} />
                                                    )}
                                                </div>
                                                
                                                {(file.extractedText?.length > 150 || file.additionalContext?.length > 150) && (
                                                    <button
                                                        onClick={() => setExpandedFiles(prev => ({
                                                            ...prev,
                                                            [file._id]: !prev[file._id]
                                                        }))}
                                                        style={{
                                                            background: 'none',
                                                            border: 'none',
                                                            color: '#AB5898',
                                                            cursor: 'pointer',
                                                            padding: '5px 0',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        {isExpanded ? 'Show less' : 'Show more'}
                                                    </button>
                                                )}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '10px',
                                                marginTop: '10px',
                                                borderTop: '1px solid #dfe1e5',
                                                paddingTop: '10px'
                                            }}>
                                                <button
                                                    onClick={handleCopy}
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        color: '#AB5898',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                        fontSize: '12px'
                                                    }}
                                                    title="Copy to clipboard"
                                                >
                                                    {isCopied ? (
                                                        <>
                                                            <Check size={12} />
                                                            Copied!
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Copy size={12} />
                                                            Copy
                                                        </>
                                                    )}
                                                </button>

                                                <button
                                                    onClick={() => handleDelete(file._id)}
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        color: '#dc3545',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                        fontSize: '12px'
                                                    }}
                                                    title="Delete file"
                                                >
                                                    <Trash2 size={12} />
                                                    Delete
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )
                    }

                </div>
                
                {/* Bottom Gradient */}
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: "150px",
                        background: "linear-gradient(to top, white, transparent, transparent)",
                        pointerEvents: "none", // Ensure the gradient doesn't block clicks
                        zIndex: 1
                    }}
                />
            </div>
            
            <div style={{backgroundColor:"white", borderRadius:'10px', border:'1px solid #dfe1e5', padding:'20px', width:'50vw', marginLeft:'10px', marginRight:'10px'}}>
                    <h3>Query Your Knowledge Base</h3>
                    <div className="query-section">
                        <textarea
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault(); // Prevent new line
                                    handleQuery();
                                }
                            }}
                            placeholder={suggestedQuery ? `Query suggestion: ${suggestedQuery}` : "Ask me anything about your knowledge..."}
                            style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                boxSizing: 'border-box'
                            }}
                        />

                        {/* Add slider control */}
                        <div style={{ marginBottom: '15px' }}>
                            <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px', color: '#666' }}>
                                Balancing Knowledge Source:
                                <span
                                    style={{
                                        fontWeight:'bold',
                                        background: 'linear-gradient(to right, #000 20%, #AB5898 70%, #5C4BD3 80%)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                        /*float: 'right' */ 
                                    }}
                                >
                                    {Math.round(dragValue) === 1 ? ' Your Knowledge Only' :
                                    Math.round(dragValue) === 2 ? ' Mostly Your Knowledge' :
                                    Math.round(dragValue) === 3 ? ' Your Knowledge + General Knowledge' :
                                    Math.round(dragValue) === 4 ? ' Mostly General Knowledge' :
                                    Math.round(dragValue) === 5 ? ' General Knowledge Only' :
                                    `${(dragValue)}`}
                                </span>
                            </label>
                            <div style={{backgroundColor:'#f8f9fa', borderRadius:'5px', padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:'10px'}}>
                                {/* Left label */}
                                <span style={{ 
                                    fontSize: '12px',
                                    color: '#666',
                                    textAlign:'left'
                                }}>
                                    Your Knowledge
                                </span>
                                {/* Slider */}
                                <div style={{ position: 'relative', width: '100%', marginLeft:'20px', marginRight:'20px'}}>
                                    {/* Markers */}
                                    {/* <div style={{
                                        position: 'absolute',
                                        width: 'calc(100% - 5px)',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0 2px',  // Adjust to align with slider ends
                                        pointerEvents: 'none'  // So clicks pass through to slider
                                    }}>
                                        {[1, 2, 3, 4, 5].map((mark) => (
                                            <div
                                                key={mark}
                                                style={{
                                                    paddingTop:'10px',
                                                    width: '3px',
                                                    height: '0px',
                                                    background: 'white',
                                                    borderRadius: '1px',
                                                    transform: 'translateX(-1.5px)'
                                                }}
                                            />
                                        ))}
                                    </div> */}
                                    <div className="tick-slider-container">
                                        <input
                                            type="range"
                                            min="1"
                                            max="5"
                                            step="0.005"
                                            value={dragValue}
                                            onChange={handleSliderDrag}
                                            onMouseUp={handleSliderRelease}
                                            onTouchEnd={handleSliderRelease}
                                            style={{
                                                width: '100%',
                                                height: '8px',
                                                borderRadius: '8px',
                                                outline: 'none',
                                                alignSelf:'center',
                                                margin: '0px',
                                                // marginLeft:'20px',
                                                // marginRight:'20px',
                                            }}
                                        />
                                        <div className="tick-marks">
                                            <div className="tick"></div>
                                            <div className="tick"></div>
                                            <div className="tick"></div>
                                        </div>
                                    </div>
                                </div>
                                {/* Right label */}
                                <span style={{ 
                                    fontSize: '12px',
                                    color: '#666',
                                    textAlign:'right'
                                }}>
                                    General Knowledge
                                </span>
                            </div>
                        </div>
                        
                        <button
                            onClick={handleQuery}
                            disabled={queryInProgress}
                            className="main-button"
                        >
                            Ask Question
                        </button>

                        {!response && queryInProgress && (
                            <div className="typing-shimmer">
                                Typing...
                            </div>
                        )}

                        {/* Progress Bar */}
                        {queryInProgress && (
                            <div style={{ marginTop: '15px' }}>
                                <ProgressBar progress={queryProgress} />
                                <div style={{ textAlign: 'center', fontSize: '14px', color: '#666' }}>
                                    {queryProgress < 5 ? 'Initializing...' :
                                    queryProgress < 20 ? 'Going through your knowledge...' :
                                    queryProgress < 40 ? 'Filtering memory...' :
                                    queryProgress < 60 ? 'Analyzing documents...' :
                                    queryProgress < 80 ? 'Making sense of the results...' :
                                    queryProgress < 100 ? 'Generating response...' :
                                    'Complete!'}
                                </div>
                            </div>
                        )}

                        {/* Response Display */}
                        {response && (
                            <div className="response-section" style={{
                                marginTop: '20px',
                                padding: '15px',
                                backgroundColor: '#f8f9fa',
                                borderRadius: '5px'
                            }}>
                                {isPartialAnalysis && analysisStats && (
                                    <div className="partial-analysis-banner">
                                        <p>
                                            Note: This answer is based on a partial analysis 
                                            ({Math.round((analysisStats.processed / analysisStats.total) * 100)}% of relevant content processed).
                                            For more comprehensive results, try refining your query.
                                        </p>
                                    </div>
                                )}
                                
                                <div className="response-text">
                                    {response}
                                </div>
                                <button 
                                    onClick={copyToClipboard}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        paddingTop: '10px',
                                        color: '#AB5898'
                                    }}
                                    title="Copy to clipboard"
                                >
                                    {copied ? <Check size={18} /> : <Copy size={18} />}
                                </button>
                                
                                {/* Metadata Display */}
                                {queryMetadata && (
                                    <div className="metadata" style={{
                                        marginTop: '15px',
                                        padding: '10px',
                                        backgroundColor: '#e9ecef',
                                        borderRadius: '5px',
                                        fontSize: '14px'
                                    }}>
                                        <div>Files Analyzed: {queryMetadata.filesAnalyzed}</div>
                                        <div>Relevant Sources: {queryMetadata.relevantSourcesFound}</div>
                                        <div>Confidence: {(queryMetadata.averageConfidence * 100).toFixed(1)}%</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {userName === "BoukarSall" && <div style={{backgroundColor:"white", borderRadius:'10px', border:'1px solid #dfe1e5', padding:'20px', marginTop: '20px', width: '50vw', boxSizing: 'border-box'}}>
                        <h3>Generate Ebook from Knowledge Base</h3>
                        <div style={{ marginBottom: '15px' }}>
                            <p>Generate a 30-page ebook about surfing from your uploaded content.</p>
                        </div>

                        {/* Progress Bar */}
                        {isGeneratingEbook && (
                            <div style={{ marginBottom: '15px' }}>
                                <div style={{
                                    width: '100%',
                                    height: '20px',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '10px',
                                    overflow: 'hidden'
                                }}>
                                    <div style={{
                                        width: `${ebookProgress}%`,
                                        height: '100%',
                                        transition: 'width 0.3s ease-in-out',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} className="progress-bar-shimmer">
                                        <span style={{ 
                                            color: 'white', 
                                            fontSize: '12px',
                                            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                                            zIndex: 1
                                        }}>
                                            {ebookProgress}%
                                        </span>
                                    </div>
                                </div>
                                <div style={{ 
                                    textAlign: 'center', 
                                    marginTop: '5px',
                                    fontSize: '14px',
                                    color: '#666'
                                }}>
                                    {getProgressMessage(ebookProgress)}
                                </div>
                            </div>
                        )}

                        {/* Error Message */}
                        {ebookError && (
                            <div style={{
                                color: '#f44336',
                                marginBottom: '15px',
                                padding: '10px',
                                backgroundColor: '#ffebee',
                                borderRadius: '5px'
                            }}>
                                {ebookError}
                            </div>
                        )}

                        {/* Generate Button */}
                        <button
                            onClick={generateEbook}
                            disabled={isGeneratingEbook || userFiles.length === 0}
                            className="main-button"
                            style={{
                                opacity: isGeneratingEbook || userFiles.length === 0 ? 0.6 : 1
                            }}
                        >
                            {isGeneratingEbook ? 'Generating...' : 'Generate Ebook'}
                        </button>
                        
                        {userFiles.length === 0 && (
                            <p style={{ 
                                color: '#666', 
                                fontSize: '14px',
                                marginTop: '10px' 
                            }}>
                                Upload some files first to generate an ebook!
                            </p>
                        )}
                    </div>
                    }

                    {/* Tips Generation Section */}
                    {userName === "BoukarSall" && <div style={{
                        backgroundColor: "white",
                        borderRadius: '10px',
                        border: '1px solid #dfe1e5',
                        padding: '20px',
                        marginTop: '20px',
                        width: '50vw',
                        boxSizing: 'border-box'
                    }}>
                        <h3>Generate Surfing Tips</h3>
                        
                        {/* Progress Bar */}
                        {isGeneratingTips && (
                            <div style={{ marginBottom: '20px' }}>
                                <div style={{
                                    width: '100%',
                                    height: '20px',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '10px',
                                    overflow: 'hidden'
                                }}>
                                    <div style={{
                                        width: `${tipsProgress}%`,
                                        height: '100%',
                                        transition: 'width 0.3s ease-in-out',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} className="progress-bar-shimmer">
                                        <span style={{
                                            color: 'white', 
                                            fontSize: '12px',
                                            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                                            zIndex: 1
                                        }}>
                                            {tipsProgress}%
                                        </span>
                                    </div>
                                </div>
                                <div style={{ 
                                    textAlign: 'center', 
                                    marginTop: '5px',
                                    fontSize: '14px',
                                    color: '#666'
                                }}>
                                    {getTipsProgressMessage(tipsProgress)}
                                </div>
                            </div>
                        )}

                        {/* Error Message */}
                        {tipsError && (
                            <div style={{
                                color: '#f44336',
                                marginBottom: '15px',
                                padding: '10px',
                                backgroundColor: '#ffebee',
                                borderRadius: '5px'
                            }}>
                                {tipsError}
                            </div>
                        )}

                        {/* Generate Button */}
                        <button
                            onClick={generateTips}
                            disabled={isGeneratingTips || userFiles.length === 0}
                            className="main-button"
                            style={{
                                opacity: isGeneratingTips || userFiles.length === 0 ? 0.6 : 1
                            }}
                        >
                            {isGeneratingTips ? 'Generating...' : 'Generate Tips List'}
                        </button>

                    {/* Tips Display */}
                    {surfingTips && (
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ 
                                maxHeight: '400px', 
                                overflowY: 'auto',
                                padding: '15px',
                                border: '1px solid #dfe1e5',
                                borderRadius: '5px'
                            }}>
                                {Object.entries(surfingTips).map(([category, data]) => (
                                    <div key={category} style={{ marginBottom: '20px' }}>
                                        <h4 style={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            alignItems: 'center' 
                                        }}>
                                            {category}
                                            <span style={{
                                                fontSize: '12px',
                                                padding: '4px 8px',
                                                borderRadius: '12px',
                                                backgroundColor: data.confidence === 'high' ? '#4CAF50' :
                                                            data.confidence === 'medium' ? '#FFA726' : '#EF5350',
                                                color: 'white'
                                            }}>
                                                {data.confidence} confidence
                                            </span>
                                        </h4>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                            {data.tips.map((tip, index) => (
                                                <li key={index} style={{ 
                                                    marginBottom: '10px',
                                                    padding: '8px',
                                                    backgroundColor: '#f8f9fa',
                                                    borderRadius: '4px'
                                                }}>
                                                    <strong>{tip.technique}:</strong> {tip.tip}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>

                            {/* Enhance Button */}
                            <button
                                onClick={enhanceTips}
                                className="main-button"
                                style={{ marginTop: '15px' }}
                            >
                                Enhance with General Knowledge
                            </button>

                            {enhancementInProgress && (
                                <div className="typing-shimmer">
                                    enhancing...
                                </div>
                            )}


                            {/* Enhanced Tips Display */}
                            {enhancedTips && (
                                <div style={{ 
                                    marginTop: '20px',
                                    padding: '15px',
                                    border: '1px solid #dfe1e5',
                                    borderRadius: '5px',
                                    backgroundColor: '#f8f9fa'
                                }}>
                                    <h3>Enhanced Tips</h3>
                                    <div style={{ 
                                        maxHeight: '400px', 
                                        overflowY: 'auto'
                                    }}>
                                        {Object.entries(enhancedTips).map(([category, data]) => (
                                            <div key={category} style={{ marginBottom: '20px' }}>
                                                <h4 style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'center' 
                                                }}>
                                                    {category}
                                                    <span style={{
                                                        fontSize: '12px',
                                                        padding: '4px 8px',
                                                        borderRadius: '12px',
                                                        backgroundColor: data.confidence === 'high' ? '#4CAF50' :
                                                                    data.confidence === 'medium' ? '#FFA726' : '#EF5350',
                                                        color: 'white'
                                                    }}>
                                                        {data.confidence} confidence
                                                    </span>
                                                </h4>
                                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    {data.tips.map((tip, index) => (
                                                        <li key={index} style={{ 
                                                            marginBottom: '10px',
                                                            padding: '8px',
                                                            backgroundColor: tip.source === 'general knowledge' ? '#e3f2fd' : 'white',
                                                            borderRadius: '4px'
                                                        }}>
                                                            <strong>{tip.technique}:</strong> {tip.tip}
                                                            {tip.source === 'general knowledge' && (
                                                                <span style={{
                                                                    fontSize: '12px',
                                                                    color: '#1976d2',
                                                                    marginLeft: '8px'
                                                                }}>
                                                                    (General Knowledge)
                                                                </span>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Custom Prompt Section */}
                            <div style={{ marginTop: '20px' }}>
                                <input
                                    type="text"
                                    value={customPrompt}
                                    onChange={(e) => setCustomPrompt(e.target.value)}
                                    placeholder="Enter your prompt (e.g., 'Make a list of 5 tweets from these tips')"
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #dfe1e5',
                                        marginBottom: '10px'
                                    }}
                                />
                                <button
                                    onClick={processCustomPrompt}
                                    disabled={isProcessingCustomPrompt}
                                    className="main-button"
                                >
                                    Process Prompt
                                </button>
                            </div>

                            {isProcessingCustomPrompt && (
                                <div className="typing-shimmer">
                                    Typing...
                                </div>
                            )}

                            {/* Custom Prompt Result */}
                            {customTipsResult && (
                                <div style={{
                                    marginTop: '15px',
                                    padding: '15px',
                                    backgroundColor: '#f8f9fa',
                                    borderRadius: '5px',
                                    whiteSpace: 'pre-wrap'
                                }}>
                                    {customTipsResult}
                                </div>
                            )}
                        </div>
                    )}
                </div>}
            </div>
            

            <div style={{backgroundColor:"white", borderRadius:'10px', border:'1px solid #dfe1e5', padding:'20px', width:'20vw'}}>
                <h3 style={{marginBottom: '10px'}}>Upload to your knowledge base:</h3>
                <div style={{ 
                    // marginTop: '10px',
                    marginBottom: '20px',
                    // textAlign: 'center',
                    color: '#666',
                    fontSize: '14px',
                    position: 'relative',
                    height: '20px'
                }}>
                    <p style={{marginBottom: '0px'}}>
                        Example: <span
                            key={currentExample}
                            style={{
                                fontStyle: 'italic',
                                position: 'absolute',
                            }}
                            className={isLastExample ? "example-text-final" : "example-text"}
                            >
                            <span style={{color: 'transparent'}}>Example: </span>{currentExample}
                            </span>
                    </p>
                    <button
                        onClick={() => {
                            setShowExamplesModal(true);
                            trackEvent('examples_modal_opened', {
                                userId,
                                action: 'open_examples_modal'
                            });
                        }}
                        style={{
                            background: 'none',
                            border: 'none',
                            color: '#007bff',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            padding: '0px'
                        }}
                    >
                        more examples
                    </button>
                </div>

                {/* Add the modal component */}
                <ExamplesModal />
                {/* Drag-and-Drop Area */}
                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed #007bff',
                        borderRadius: '8px',
                        padding: '20px',
                        paddingBottom: '10px',
                        paddingTop: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        alignSelf:'center',
                        justifySelf:'center',
                        backgroundColor: isDragActive ? '#f0f8ff' : 'white',
                        boxSizing: 'border-box'
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p style={{fontSize:'14px'}}>Drop the file here...</p>
                    ) : (
                        <p style={{fontSize:'14px'}}>Drag & drop a PDF or image file here, or click to select one</p>
                    )}
                </div>

                {/* Manual File Selection */}
                {/* <input type="file" onChange={(e) => handleFileChange(e.target.files[0])} /> */}

                <div style={{display:"flex", justifySelf:"center", alignContent:"center", justifyContent:"center"}}>
                    <p style={{justifySelf:"center", fontSize:'14px'}}>
                        {file ? "" : "OR"}
                    </p>
                </div>

                {/* Add website URL input */}
                <div>
                    <input
                        type="text"
                        placeholder="Paste a website URL..."
                        value={websiteUrl}
                        onChange={handleUrlChange}
                        className={`url-input ${urlError ? 'error' : ''}`}
                        aria-invalid={!!urlError}
                        aria-describedby="url-error"
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #dfe1e5',
                            fontSize: '13px'
                        }}
                    />
                    {urlError && (
                        <small 
                            id="url-error" 
                            className="error-message"
                            style={{ 
                                color: '#dc3545',
                                fontSize: '12px',
                                marginTop: '4px',
                                display: 'block'
                            }}
                        >
                            {urlError}
                        </small>
                    )}
                    {/* <small style={{ color: '#666', fontSize: '12px' }}>
                        Example: https://example.com or www.example.com
                    </small> */}
                </div>

                {file || websiteUrl ?
                <div style={{display:"flex", justifySelf:"center", alignContent:"center", justifyContent:"center", height:'10px'}}>
                </div> :
                <div style={{display:"flex", justifySelf:"center", alignContent:"center", justifyContent:"center"}}>
                    <p style={{justifySelf:"center", fontSize:'14px'}}>
                        OR
                    </p>
                </div>
                }

                <textarea
                    placeholder={file ? "Add additional context (optional)..." : "Write or paste text here..."}
                    value={additionalContext}
                    onChange={(e) => setAdditionalContext(e.target.value)}
                    style={{
                        width: '100%',  // Takes full width
                        boxSizing: 'border-box',  // Important! Includes padding in width calculation
                        height: '100px',
                        borderRadius:'10px',
                        fontSize:'13px',
                        padding: '14px',
                        marginBottom:'10px',
                        borderColor: '#dfe1e5'
                    }}
                />

                {/* Title Field */}
                <input
                    type="text"
                    placeholder="Enter a title..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{
                        width: '100%',  // Takes full width
                        boxSizing: 'border-box',  // Important! Includes padding in width calculation
                        marginBottom: '10px',
                        padding: '10px', 
                        borderRadius: '5px', 
                        border: '1px solid #dfe1e5' 
                    }}
                />
                
                {/* Upload Button */}
                <div style={{display:"flex", flexDirection:"row", justifyItems:"center"}}>
                    <button
                        onClick={handleUpload}
                        className={`main-button ${(!file && !additionalContext.trim() && !websiteUrl.trim()) ? 'inactive' : ''}`}>
                        Upload
                    </button>
                    {/* Show shimmer effect when uploading */}
                    {uploading && (
                        <div className="shimmer">
                            <p style={{paddingLeft:'10px', fontSize:'12px'}}>Uploading..</p>
                            {/* <span>Uploading...</span> */}
                    </div>
                )}
                </div>

                {/* Status Message */}
                {message && <p>{message}</p>}

                {/* Extracted Text */}
                {extractedText && (
                    <div>
                        <h2>Extracted Text</h2>
                        <p>{extractedText}</p>
                    </div>
                )}

                {/* Uploaded File URL */}
                {fileUrl && (
                    <p>
                        File URL: <a href={fileUrl}>{fileUrl}</a>
                    </p>
                )}

                <p style={{fontSize:'12px'}}>
                    For better query results, upload short documents and put as many relevant keywords as possible in the title.
                </p>

            </div>

            </div>

            <FeedbackButton username={userName} />
        </div>
    );
}

export default FileUpload;