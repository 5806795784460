import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import FileUpload from './FileUpload';
import FilesPage from './FilesPage';
import FileUploadDark from './FileUploadDark';
import AdminDashboard from './components/AdminDashboard';
import ConfidentialityRules from './ConfidentialityRules';
import { isTokenExpired } from './utils/jwt';
import { initAmplitude } from './utils/amplitude';
import Dashboard from './Dashboard';
import Password from './password';
// Initialize Amplitude when your app starts
initAmplitude();

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<TokenCheck />} />
            <Route path="/landing-page" element={<LandingPage />} />
            <Route
                path="/dashboard"
                element={
                    <RequireAuth>
                        <Dashboard />
                    </RequireAuth>
                }
            />
            <Route
                path="/upload"
                element={
                    <RequireAuth>
                        <FileUpload />
                    </RequireAuth>
                }
            />
            <Route
                path="/files"
                element={
                    <RequireAuth>
                        <FilesPage />
                    </RequireAuth>
                }
            />
            <Route path="/password" element={<Password />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/d" element={<FileUploadDark />} />
            <Route path="/confidentiality-rules" element={<ConfidentialityRules />} />
        </Routes>
    </Router>
  );
}

// Component to check token and redirect accordingly
function TokenCheck() {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    React.useEffect(() => {
        if (tokenFromUrl) {
            localStorage.setItem('token', tokenFromUrl);
            window.history.replaceState({}, document.title, window.location.pathname);
            window.location.href = '/dashboard';
            return;
        }

        const token = localStorage.getItem('token');
        if (token && !isTokenExpired(token)) {
            window.location.href = '/dashboard';
        } else {
            window.location.href = '/landing-page';
        }
    }, [tokenFromUrl]);

    // Render this while the redirect happens
    return (
        <div>
            <div style={{ 
                position: 'fixed', 
                bottom: '20px', 
                left: '0', 
                width: '100%', 
                textAlign: 'center',
                padding: '10px'
            }}>
                <a href="/confidentiality-rules" style={{ color: '#666', textDecoration: 'none' }}>
                    Privacy Policy
                </a>
            </div>
        </div>
    );
}

// Higher-order component for protected routes
function RequireAuth({ children }) {
  const token = localStorage.getItem('token');

    // Check for token existence and validity
    if (!token || isTokenExpired(token)) {
        return <Navigate to="/landing-page" />;
    }

    return children;
}

export default App;
