import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from './utils/amplitude'; // Import the trackEvent function


function Password() {
    const navigate = useNavigate();

    useEffect(() => {
        // Track page view
        trackEvent('page_viewed', { pageName: 'Password' });
    }, [navigate]);



    const [showJumbo, setShowJumbo] = useState(true);
    const [jumboOpacity, setJumboOpacity] = useState(1);

    useEffect(() => {
        // Arrêter l'animation après 10 secondes
        const timer = setTimeout(() => {
            setShowJumbo(false);
        }, 10000); // 10000ms = 10 secondes

        return () => clearTimeout(timer);
        
    }, []);

    useEffect(() => {
        // Créer un intervalle qui diminue l'opacité toutes les 2 secondes
        const fadeInterval = setInterval(() => {
            setJumboOpacity(prevOpacity => {
                if (prevOpacity <= 0) {
                    clearInterval(fadeInterval); // Arrêter l'intervalle quand l'opacité atteint 0
                    return 0;
                }
                return prevOpacity - 0.2; // Diminuer l'opacité de 0.1
            });
        }, 1000); // Toutes les secondes

        // Nettoyer l'intervalle après 5 étapes (10 secondes total pour le fade)
        setTimeout(() => clearInterval(fadeInterval), 10000);

        return () => clearInterval(fadeInterval);
        
    }, []);
    

    return (
        <div style={{ textAlign: 'center' }}>

            <div class="card card--4" style={{position:'absolute', /*top:'400vh'*/}}>
                <div class="relative flex flex-col h-[100vh] items-center justify-center transition-bg">
                    <div class="absolute inset-0 overflow-hidden">
                        <div class={showJumbo ? "jumbo absolute -inset-[10px]" : "absolute -inset-[10px]"} style={{height:'100%', width:'100%', transition: 'all 4s ease-in-out', opacity: jumboOpacity,}}>
                            <div style={{height:'500px', width:'100px'}}/>
                        </div>

                        {/* Secret code */}
                        <section style={{width:'90%', maxWidth:'500px', alignSelf:'center', justifySelf:'center', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}>
                            <p>Glide To Reveal Secret Code</p>
                            <ul className="code">
                                <li tabIndex="0" className ="digit">
                                <span>B</span>
                                </li>
                                <li tabIndex="0" className="digit">
                                <span>3</span>
                                </li>
                                <li tabIndex="0" className="digit">
                                <span>T</span>
                                </li>
                                <li tabIndex="0" className="digit">
                                <span>4</span>
                                </li>
                            </ul>
                        </section>

                        {/* back to landing page */}
                        <button onClick={() => navigate('/landing-page')} class="gradient-button" style={{position:'absolute', bottom:'50px', left:'50%', transform:'translateX(-50%)', borderRadius:'10px'}}>Back to landing page</button>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Password;