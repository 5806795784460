import axios from 'axios';
import { isTokenExpired } from './utils/jwt';

// Retrieve the token from localStorage
const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token || isTokenExpired(token)) {
        // Redirect to the landing page if token is expired or missing
        window.location.href = '/';
        return null;
    }
    return token;
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://proto-backend-d2c1638486b9.herokuapp.com'
});

// Add request interceptor to include token
api.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Add response interceptor for debugging
api.interceptors.response.use(
    response => {
        console.log('Response received:', response.status, response.data);
        return response;
    },
    error => {
        if (error.response?.status === 401) {
            // Token expired or invalid, redirect to landing page
            console.log('Token expired or invalid, redirecting to landing page');
            localStorage.removeItem('token'); // Clear the token
            window.location.href = '/'; // Redirect to landing page
        }
        console.error('Response error:', error.response?.status, error.response?.data);
        return Promise.reject(error);
    }
);

// Profile routes
api.getUserProfile = () => api.get('/api/user-profile');
api.updateProfile = (profileData) => api.post('/api/update-profile', profileData);
api.updateProspectingProfile = (prospectingProfile) => api.post('/api/update-prospecting-profile', prospectingProfile);
api.markVisited = () => api.post('/api/mark-visited');
api.getUserFiles = () => api.get('/api/files');
api.getVoices = () => api.get('/api/voices');
api.generateSpeech = (text, voiceId, prospectId, forceNew = false) => 
    api.post('/api/text-to-speech', 
        { text, voiceId, prospectId, forceNew }, 
        // { responseType: 'blob' }
    );

api.cloneVoice = (formData) => api.post('/api/clone-voice', formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

api.testClonedVoice = (text, voiceId) => {
    return api.post('/api/test-cloned-voice', { 
        text, 
        voiceId 
    });
};

api.saveVideos = (formData) => api.post('/api/save-videos', formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    timeout: 120000, // 2 minutes
    onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log('Upload progress:', percentCompleted);
    }
});

api.getUserVideos = () => api.get('/api/user/videos');

// LinkedIn prospects routes
/* OLD way, with the scraping from Naveed
api.scrapeLinkedInProfile = (url) => api.post('/api/scrape-linkedin-profile', { url });*/
// NEW way, with the scraping from Scrapin.io
api.scrapeLinkedInProfile = (linkedinUrl) => {
    return api.post('api/prospects/scrape-profile', { linkedinUrl });
};
api.getLinkedInProspects = () => api.get('/api/prospects');
api.deleteProspect = (prospectId) => api.delete(`/api/prospects/${prospectId}`);
api.generatePitch = (prospectId) => api.post(`/api/prospects/generate-pitch/${prospectId}`);
api.updateProspectPitch = (prospectId, pitch) => api.patch(`/api/prospects/${prospectId}/pitch`, { pitch });
api.extractLinkedInInfo = (formData) => {
    // Add a boundary to ensure proper multipart formatting
    // const boundary = '----WebKitFormBoundary' + Math.random().toString(36).substring(2);
    
    return api.post('/api/prospects/extract-linkedin-info', formData, {
        headers: {
            'Accept': 'application/json',
            // Let the browser set the Content-Type with boundary
        },
        // Increase timeout for large files
        timeout: 30000,
        onUploadProgress: (progressEvent) => {
            console.log('Upload Progress:', {
                loaded: progressEvent.loaded,
                total: progressEvent.total,
                progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
            });
        },
        // Ensure proper handling of binary data
        responseType: 'json',
        maxBodyLength: Infinity,
        maxContentLength: Infinity
    });
};

api.getUploadUrl = (fileType, videoCategory) => 
    api.post('/api/get-upload-url', { 
        fileType, 
        videoCategory 
    }).catch(error => {
        console.error('Error getting upload URL:', error);
        throw error;
    });

api.uploadToS3 = async (url, file) => {
    try {
        console.log('Starting S3 upload...', {
            url: url.substring(0, 100), // Log first 100 chars of URL
            fileType: file.type,
            fileSize: file.size
        });

        const response = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
            },
            maxContentLength: Infinity,
            maxBodyLength: Infinity
        });

        console.log('S3 upload successful:', response.status);
        return response;
    } catch (error) {
        console.error('S3 upload error:', {
            message: error.message,
            status: error.response?.status,
            data: error.response?.data
        });
        throw error;
    }
};

api.confirmUpload = (key, videoCategory) => 
    api.post('/api/confirm-upload', { 
        key, 
        videoCategory 
    }).catch(error => {
        console.error('Error confirming upload:', error);
        throw error;
    });

api.deleteVideo = (category) => 
    api.delete(`/api/videos/${category}`);

api.saveRecording = (formData) => {
    return api.post('/api/save-recording', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

api.deleteRecording = (recordingId) => api.delete(`/api/recording/${recordingId}`);

api.saveFile = (formData) => api.post('/api/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log('Upload progress:', percentCompleted);
    }
});

api.generateVideo = (data) => api.post('/api/generate', data, {
    headers: {
        'Content-Type': 'application/json'
    }
});

api.checkVideoStatus = (prospectId) => 
    api.get(`/api/videos/status/${prospectId}`);

export default api;