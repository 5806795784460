import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail } from 'react-feather'; // Feather icons
import { trackEvent } from './utils/amplitude'; // Import the trackEvent function


function LandingPage() {
    const navigate = useNavigate();

    useEffect(() => {
        // Track page view
        trackEvent('page_viewed', { pageName: 'LandingPage' });
        
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        console.log('Extracted Token:', token); // Debug log
        if (token) {
            localStorage.setItem('token', token); // Save the token in localStorage
            console.log('Token stored in localStorage:', localStorage.getItem('token')); // Debug log
            navigate('/dashboard'); // Redirect to the dashboard page
        };
    }, [navigate]);

    const handleGoogleSignup = () => {
        console.log('Redirecting to Google Sign-In...');
        trackEvent('button_clicked', { buttonName: 'Google Signup' });
        window.location.href = 'https://proto-backend-d2c1638486b9.herokuapp.com/auth/google'; // Backend Google auth route
    };

    // const handleNavigateToDark = () => {
    //     navigate('/d');
    // };

    const [waitlistEmail, setWaitlistEmail] = useState('');
    const [waitlistMessage, setWaitlistMessage] = useState('');

    const handleWaitlistSignup = async () => {
        if (!waitlistEmail) {
            setWaitlistMessage('Please enter a valid email.');
            return;
        }
    
        try {
            const response = await fetch('hhttps://proto-backend-d2c1638486b9.herokuapp.com/join-waitlist', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: waitlistEmail }),
            });
    
            if (response.ok) {
                trackEvent('waitlist_signup', { email: waitlistEmail });

                setWaitlistMessage('Thank you for joining the waitlist!');
                setWaitlistEmail(''); // Clear the input field
            } else {
                setWaitlistMessage('Failed to join the waitlist. Please try again.');
            }
        } catch (error) {
            console.error('Error joining waitlist:', error);
            setWaitlistMessage('An error occurred. Please try again.');
        }
    };
   
    // Add this new useEffect for the animation of the Merra.ai to Memorable text // Masked because not used
    /*useEffect(() => {
        const interval = setInterval(() => {
            // Start fade out
            setIsTransitioning(true);
            
            // Change to "Memorable" after fade out
            setTimeout(() => {
                setDisplayTextFade("Memorable");
                setIsTransitioning(false);
            }, 500);

            // Start fade out for changing back
            setTimeout(() => {
                setIsTransitioning(true);
                
                // Change back to "Merra.ai" after fade out
                setTimeout(() => {
                    setDisplayTextFade("Merra.ai");
                    setIsTransitioning(false);
                }, 500);
            }, 3000);
        }, 8000);

        return () => clearInterval(interval);
    }, []);*/

    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === 'B3T4') {  // Replace with your desired password
            setIsAuthenticated(true);
            setErrorMessage('');
        } else {
            setErrorMessage('Incorrect password');
        }
    };

    const [showJumbo, setShowJumbo] = useState(true);
    const [jumboOpacity, setJumboOpacity] = useState(1);

    useEffect(() => {
        // Arrêter l'animation après 10 secondes
        const timer = setTimeout(() => {
            setShowJumbo(false);
        }, 10000); // 10000ms = 10 secondes

        return () => clearTimeout(timer);
        
    }, []);

    useEffect(() => {
        // Créer un intervalle qui diminue l'opacité toutes les 2 secondes
        const fadeInterval = setInterval(() => {
            setJumboOpacity(prevOpacity => {
                if (prevOpacity <= 0) {
                    clearInterval(fadeInterval); // Arrêter l'intervalle quand l'opacité atteint 0
                    return 0;
                }
                return prevOpacity - 0.2; // Diminuer l'opacité de 0.1
            });
        }, 1000); // Toutes les secondes

        // Nettoyer l'intervalle après 5 étapes (10 secondes total pour le fade)
        setTimeout(() => clearInterval(fadeInterval), 10000);

        return () => clearInterval(fadeInterval);
        
    }, []);
    

    return (
        <div style={{ textAlign: 'center' }}>

            <div class="card card--4" style={{position:'absolute', /*top:'400vh'*/}}>
                <div class="relative flex flex-col h-[100vh] items-center justify-center transition-bg">
                    <div class="absolute inset-0 overflow-hidden">
                        <div class={showJumbo ? "jumbo absolute -inset-[10px]" : "absolute -inset-[10px]"} style={{height:'100%', width:'100%', transition: 'all 4s ease-in-out', opacity: jumboOpacity,}}>
                            <div style={{height:'450px', width:'100px'}}/>
                        </div>
                        <h1 className="gradient-text" style={{position:'absolute', top:'100px', left:'0px', right:'0px'}}>Merra.ai<br/><h3>Connecting dots</h3></h1>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                            {/* <p style={{width:'50vw', justifySelf:'center', paddingBottom:'50px', color:'grey'}}>
                                Let AI get personal by building your own knowledge base.
                                <br />
                                <br />
                                Merra.ai gives you a photographic memory,
                                capturing everything you allow it to see
                                and letting you instantly recall or
                                query your own knowledge base.
                                From uploaded files and notes to insights from your screens,
                                it helps you remember, organize, and act.
                                <br />
                                Start building your personal knowledge base today.
                                <br />
                                And unlock your true potential.
                            </p> */}
                        </div>

                        {!isAuthenticated ? (
                            <div style={{marginTop: '0px'}}>
                                <form onSubmit={handlePasswordSubmit}>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter password"
                                        style={{
                                            marginRight: '10px',
                                            borderRadius: '10px',
                                            border: '1px solid #ccc',
                                            backgroundColor: 'transparent',
                                            padding: '10px',
                                            fontSize: '15px',
                                        }}
                                    />
                                    <button onClick={handleWaitlistSignup} class='waitlist-button' style={{margin:'10px', borderWidth:'Opx', top:'-2px'}}>
                                        <span>Submit</span>
                                        <span class="waitlist-shimmer"></span>
                                    </button>
                                </form>
                                {errorMessage && <p style={{color: 'red', marginTop: '10px'}}>{errorMessage}</p>}
                            </div>
                        ) : (
                            <>
                                <p>
                                    Use your google account to sign up:
                                </p>
                                <button onClick={handleGoogleSignup} className='google-btn'>
                                    <div className="google-icon"/>
                                    Continue with Google
                                </button>
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <p>
                                        <Link to="/confidentiality-rules" style={{ color: 'blue', textDecoration: 'underline' }}>
                                            View our Confidentiality Rules
                                        </Link>
                                    </p>
                                </div>
                            </>
                        )}
                        <br />
                        <div>
                            <img 
                                src={`/memoji7.png`} 
                                alt="Memoji" 
                                style={{ marginTop: '30px', width: '50px', height:'50px', borderRadius:'25px', marginLeft:'5px', marginRight:'5px'}} 
                            />
                            <img 
                                src={`/memoji2.png`} 
                                alt="Memoji" 
                                style={{ marginTop: '30px', width: '50px', height:'50px', borderRadius:'25px', marginLeft:'5px', marginRight:'5px'}} 
                            />
                            <img 
                                src={`/memoji3.png`} 
                                alt="Memoji"
                                style={{ marginTop: '30px', width: '50px', height:'50px', borderRadius:'25px', marginLeft:'5px', marginRight:'5px'}} 
                            />
                            <img 
                                src={`/memoji4.png`} 
                                alt="Memoji" 
                                style={{ marginTop: '30px', width: '50px', height:'50px', borderRadius:'25px', marginLeft:'5px', marginRight:'5px'}} 
                            />
                            <img 
                                src={`/memoji5.png`} 
                                alt="Memoji" 
                                style={{ marginTop: '30px', width: '50px', height:'50px', borderRadius:'25px', marginLeft:'5px', marginRight:'5px'}} 
                            />
                        </div>
                        <p>
                            Made by Boukar Sall <Mail size={15} /> boukar.o.sall[at]gmail.com
                            <br/>
                            If you have any questions, feel free to reach out
                        </p>
                        <a href="https://www.w3schools.com">
                            <img 
                                src={`https://cdn-icons-png.flaticon.com/512/174/174857.png`} 
                                alt="linkedin" 
                                style={{ width: '15px', height:'15px', borderRadius:'2px', marginRight:'5px'}} 
                            />
                            linkedin.com/in/boukar-sall</a>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        {/* Differentiation text*/}
                        {/* <p style={{width:'50vw', justifySelf:'center', marginTop:'60px', paddingBottom:'50px', color:'black', maxWidth:'600px'}}>
                            Unlike other AI tools, which prioritize quick, instant answers, we approach things differently. While most AI chatbots rely on memory-based summaries, we re-query your information every time to ensure nothing important about you is ever forgotten.

                            <br/><br/>We also combine answers from the best large language models (LLMs) out there—like ChatGPT, Gemini, Claude, and more — to generate the most accurate and thoughtful responses.

                            <br/><br/>Your data is in good hands. We’ve built robust security and privacy systems to keep everything safe, and we make it simple for you to delete any or all of your personal data whenever you want. That said, we don’t encourage you to share sensitive details like passwords—we’re here to make your life easier, not riskier.

                            <br/><br/>Trustworthy, reliable, and personalized — that’s how we do things.

                            <br/><br/>As we shift to an economy where knowledge is the primary driver of value creation, launching Merra.ai just made sense, as we believe that your personal knowledge base is your most valuable asset.

                            <br/><br/>Just imagine where you could be in a year if you started building your personal knowledge base today.
                        </p> */}
                        <div class="scroll" style={{marginTop:'30px'}}/>
                        {/* Waitlist modal */}
                        <div class="modal" style={{marginBottom:'50px', marginTop:'100px'}}>
                            <span style={{fontWeight:'bold'}}>Join our waitlist</span>
                            <div class="field-wrapper">
                                <input value={waitlistEmail} onChange={(e) => setWaitlistEmail(e.target.value)} autocomplete="off" name="hidden" class="field" maxlength="256" placeholder="Your email" type="text" id="mail" required style={{fontSize:'15px'}}>
                                    {/* <button class="sign">
                                        <svg width="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                                    </button> */}
                                </input>
                                <button onClick={handleWaitlistSignup} class='waitlist-button' style={{margin:'10px', marginTop:'25px', borderWidth:'Opx'}}>
                                    <span>Join waitlist</span>
                                    <span class="waitlist-shimmer"></span>
                                </button>
                                {waitlistMessage && <p style={{ marginTop: '10px', color: 'green' }}>{waitlistMessage}</p>}
                            </div>
                        </div>
                        <br />
                        <br />
                        {/* Name explanation */}
                        <div style={{ textAlign: 'center', marginTop: '0px', padding: '20px', marginBottom:'100px', paddingRight:'100px', paddingLeft:'100px', /*backgroundColor: '#f9f9f9',*/ borderRadius: '8px', width:'50vw'}}>
                            <h2>Why "Merra"?</h2>
                            <p>
                                The name "Merra" is inspired by the word "mera," which carries profound and diverse meanings across cultures and languages. 
                                <br/>
                                <br/>
                                In Hindi, "mera" (मेरा) means "mine," evoking a sense of personal connection and ownership. 
                                <br/>
                                <br/>
                                In Portuguese and Spanish, it signifies something "pure," "authentic," or "genuine," reflecting clarity and simplicity. 
                                <br/>
                                <br/>
                                In Greek, "mera" (μέρα) means "day," symbolizing new beginnings and opportunities. 
                            </p>
                        </div>

                        {/* Mission */}
                        <div style={{ textAlign: 'center', marginTop: '0px', padding: '20px', marginBottom:'100px', paddingRight:'100px', paddingLeft:'100px', /*backgroundColor: '#f9f9f9',*/ borderRadius: '8px', width:'50vw'}}>
                            <h2>Our Purpose</h2>
                            <p>
                                Merra is on a mission to make cutting-edge AI available and adopted.
                                <br/>
                                <br/>
                                When most are focused on the technological aspect of AI, we find use-cases, then we build and market products for the most advanced AI technologies.
                                <br/>
                                <br/>
                                Our first product helps you create AI-generated customised linkedin outreach videos.
                            </p>
                        </div>

                        {/*                         
                        <section>
                            <p>Glide To Reveal Secret Code</p>
                            <ul class="code">
                                <li tabindex="0" class="digit">
                                <span>0</span>
                                </li>
                                <li tabindex="0" class="digit">
                                <span>3</span>
                                </li>
                                <li tabindex="0" class="digit">
                                <span>4</span>
                                </li>
                                <li tabindex="0" class="digit">
                                <span>8</span>
                                </li>
                                <li tabindex="0" class="digit">
                                <span>7</span>
                                </li>
                                <li tabindex="0" class="digit">
                                <span>2</span>
                                </li>
                            </ul>
                        </section>
                         */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;